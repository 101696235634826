import i18n from '@/lang/i18n';

export const PERPOSE_USING_BUILDING = {
    OFFICE: i18n.t('casbee.option_office'),
    GOVERMNET_OFFICE: i18n.t('casbee.option_government_offices'),
    RESEARCH_INSTITUTE: i18n.t('casbee.option_research_facility'),
    PRE_SCHOOL: i18n.t('casbee.option_kindergarten_nursery'),
    JUNIOR_HIGHT_SCHOOL_HOKKAIDO: i18n.t('casbee.option_elementary_junior_school'),
    JUNIOR_HIGHT_SCHOOL_OTHER: i18n.t('casbee.option_elementary_junior_school_other'),
    HIGHT_SCHOOL: i18n.t('casbee.option_high_school'),
    UNIVERSITY: i18n.t('casbee.option_universities_school'),
    SHOPPING_MALL: i18n.t('casbee.option_department_store'),
    GROCERY_STORE: i18n.t('casbee.option_convenience_store'),
    HOME_ELECTRICAL_STORE: i18n.t('casbee.option_electronics_store'),
    OTHER_STORE: i18n.t('casbee.option_other_product_sales'),
    EATERY: i18n.t('casbee.option_restaurant'),
    THEATER: i18n.t('casbee.option_theater_hall'),
    EXHIBITION_FACLITIES: i18n.t('casbee.option_exhibition_facility'),
    STADIUM: i18n.t('casbee.option_sports_facilities'),
    FACTORY: i18n.t('casbee.option_factory'),
    HOSPITAL: i18n.t('casbee.option_hospital'),
    HOTEL: i18n.t('casbee.option_hotel_ryokan')
}

export const VALUE_CALCULATION_DEFAULT = {
    CALC_ELECTRIC: 9.76,
    CALC_GAS: 0.0498,
    CALC_OTHERS: 0.0686,
    ROUND_NUMBER: 4,
    CALC_TOTAL: 1000
}



export const DATA_FOR_CALCULATION = [
    {
        type: i18n.t('casbee.option_office'),
        electricity: 78,
        gas: 6,
        others: 16
    },
    {
        type: i18n.t('casbee.option_government_offices'),
        electricity: 77,
        gas: 9,
        others: 14
    },
    {
        type: i18n.t('casbee.option_research_facility'),
        electricity: 63,
        gas: 8,
        others: 29
    },
    {
        type: i18n.t('casbee.option_kindergarten_nursery'),
        electricity: 70,
        gas: 12,
        others: 18
    },
    {
        type: i18n.t('casbee.option_elementary_junior_school'),
        electricity: 67,
        gas: 20,
        others: 13
    },
    {
        type:i18n.t('casbee.option_elementary_junior_school_other'),
        electricity: 74,
        gas: 12,
        others: 14
    },
    {
        type: i18n.t('casbee.option_high_school'),
        electricity: 74,
        gas: 7,
        others: 19
    },
    {
        type: i18n.t('casbee.option_universities_school'),
        electricity: 66,
        gas: 11,
        others: 23
    },
    {
        type: i18n.t('casbee.option_department_store'),
        electricity: 73,
        gas: 4,
        others: 23
    },
    {
        type: i18n.t('casbee.option_convenience_store'),
        electricity: 100,
        gas: 0,
        others: 0
    },
    {
        type: i18n.t('casbee.option_electronics_store'),
        electricity: 71,
        gas: 1,
        others: 28
    },
    {
        type: i18n.t('casbee.option_other_product_sales'),
        electricity: 89,
        gas: 3,
        others: 8
    },
    {
        type: i18n.t('casbee.option_restaurant'),
        electricity: 51,
        gas: 33,
        others: 16
    },
    {
        type: i18n.t('casbee.option_theater_hall'),
        electricity: 73,
        gas: 13,
        others: 14
    },
    {
        type: i18n.t('casbee.option_exhibition_facility'),
        electricity: 77,
        gas: 7,
        others: 16
    },
    {
        type: i18n.t('casbee.option_sports_facilities'),
        electricity: 69,
        gas: 16,
        others: 15
    },
    {
        type: i18n.t('casbee.option_factory'),
        electricity: 100,
        gas: 0,
        others: 0
    },
    {
        type: i18n.t('casbee.option_hospital'),
        electricity: 48,
        gas: 13,
        others: 39
    },
    {
        type: i18n.t('casbee.option_hotel_ryokan'),
        electricity: 42,
        gas: 14,
        others: 44
    }
];